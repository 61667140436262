<template>
  <div class="out">
          <div class="header">
              <div class="logo Title">
                  
                  <div class="Chinese"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.trMarkValue')}}</div>
              </div>
              <div class="right-icon">
                  <img src="../assets/img/logo-icon.png" alt="">
                 
              </div>
          </div>
          <div class="body">
            <div class="item_text">
                <div>{{$t('main.trname')}}：</div>
                <div style="color:#0056d7">{{name}}</div>
            </div>
            <div class="item_text">
                <div>{{$t('main.gwtc')}}</div>
                <div style="color:#fe0000">{{serial_number}}</div>
            </div>
            <div class="item_text">
                <div>{{$t('main.trnestvalue')}}：</div>
                <div>￥{{thousands(price)}}</div>
            </div>
            <div class="grap_chart" ref="chart"></div>
            <div class="list_price">
                <div class="cw_title">{{$t('main.MarketValue')}}</div>
                <div class="list_item" v-for="(item , index ) in list" :key="index">
                    <div>
                        <img src="../assets/img/date.png" alt="">
                        {{item.created_at.split(' ')[0]}}
                    </div>
                    <div>
                        <img src="../assets/img/price.png" alt="">
                        ￥{{thousands(item.price)}}
                    </div>
                </div>
            </div>
          </div>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
export default {
    data(){
        return{
            name:'',
            serial_number:'',
            price:'',
            list:[]
        }
    },
    async mounted(){
        let query = {}
        if(this.$route.query.__params){
            let json = JSON.parse(this.$base64.decode(this.$route.query.__params))
            
            query = json
            this.$route.params.id = query.id
        }
        await this.axios.get(`/collection/value_curve?goods_id=${this.$route.params.id}`).then((res)=>{
            
            this.name = res.data.data.goods.name
            this.serial_number = res.data.data.goods.serial_number
            this.price = res.data.data.data.length? res.data.data.data[0].price : res.data.data.goods.price
            this.list = res.data.data.data
        })
        this.chart =  echarts.init(this.$refs.chart)
        let option = {
        xAxis: {
            type: 'category',
            data: [],
            axisPointer:{
                value:'',
                snap:true,
                handle: {
                    show: true,
                    color: '#7581BD'
                },
                lineStyle: {
                    color: '#7581BD',
                    width: 2
                }
            },
            splitLine: {
      show: false
    }
        },
        yAxis: {
            type: 'value',
            min: function (value) {
            let val = Math.min(Math.abs(value.min), Math.abs(value.max));
            return val; // 该轴百分比有正负值
        },
        max: function (value) {
            let val = Math.max(Math.abs(value.min), Math.abs(value.max));
            return val ;
        },
        axisLabel:{
            formatter:(value)=>{
                if(parseInt(value) > 1000000){
                    let new_value =  (parseFloat(value )/ 1000000)
                    return `${new_value} 百万`
                }else if(parseInt(value) > 10000){
                    let new_value =  (parseFloat(value )/ 10000)
                    return `${new_value} 万`
                }else{
                    return value
                }
            }
        }
            
        },
        grid:{
            containLabel:true
        },
        series: [
            {
            data: [],
            type: 'line',
            smooth: true,
            areaStyle: {
                color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(58,132,255, 0.5)' // 0% 处的颜色
                }, {
                    offset: 1, color: 'rgba(58,132,255, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
            },
            
            }
        ],
        tooltip:{
                show:true,
                alwaysShowContent:true,
                triggerOn: "none",
                confine:true,
                alwaysShowContent: true,
                position: function(pt) {
                    return [pt[0], 10];
                },
                valueFormatter: (value) => '￥' + value.toFixed(2)
            }
        };
        for(let i =0; i < this.list.length; i++){
            option.xAxis.data.unshift(this.list[i].created_at.split(' ')[0])
            option.series[0].data.unshift(parseFloat(this.list[i].price))
        }
        this.chart.setOption(option)
    },
    methods:{
        format_with_regex(number) {
        return !(number + '').includes('.')
            ? // 就是说1-3位后面一定要匹配3位
            (number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
                return match + ',';
            })
            : (number + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
                return match + ',';
            });
        },
        thousands(num){
        var splits=[],res=[];
        var splits = num.toString().split(".");
        splits[0].split("").reverse().map(function(item,i){
            if(i%3 == 0 && i!=0){ res.push(","); }
            res.push(item);
        });
        return res.reverse().join("")+(splits.length>1 ? "."+splits[1] : "");
        },
    }
}
</script>

<style scoped>
.out {
		padding: 0.15rem;
		min-height: 100vh;
		overflow: auto;
		box-sizing: border-box;
		box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
	}

	.header {
		display: flex;
		justify-content: space-between;
		/* line-height: 0; */
		margin-bottom: 0;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
		margin-left: -0.15rem;
		margin-right: -0.15rem;
		margin-top:-0.14rem;
		margin-bottom: 0.15rem;
		padding: 0 0.15rem;
		align-items: center;
		background: #fff;
		display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 0.05rem 0.15rem;
    align-items: center;
    background: #fff;
	
	}
	
	.Title {
		font-size: 0.18rem;
		position: relative;
	}

	.English {
		color: #1b6a21;
		opacity: 0.3;
		position: relative;
		top: 0.18rem;
		left: 0.15rem;
		text-align: left;
	}

	.Chinese {
		color: #1b6a21;
		/* top: -0.2rem; */
		left: 0;
		position: relative;
		text-align: left;
	}

	.Chinese .count {
		margin-left: 0.5rem;
	}

	.cc-wc {
		font-size: 0.1rem;
		color: #e9630a;
	}
	
	.right-icon {
  width: 1rem;
  height: 0.39rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}
    .body{
        width: 100%;
        box-sizing: border-box;
        padding: 0.15rem;
    }
    .item_text{
        padding: 0 0.1rem;
        margin-bottom: 0.1rem;
        font-size: 0.15rem;
        display: flex;
        align-items: center;
        color: #343434;
    }
    .item_text >div:nth-child(1){
        width: 1.1rem;
    }
    .grap_chart{
        width: 100%;
        height: 3.13rem;
        background: #fff;
    }
    .list_price{
        margin-top: 0.35rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .cw_title{
    position: relative;
    margin-bottom: 0.25rem;
    
}
.cw_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
}
.cw_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
    transform: rotateY(180deg);
}
.list_item{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0.14rem 0.1rem;
    background: #f2f1ed;
    margin-bottom: 0.1rem;
    font-size: 0.12rem;
    align-items: center;

}
.list_item > div{
    display: flex;
    align-items: center;
}
.list_item > div:nth-child(1){
    color: #707070;
}
.list_item > div:nth-child(2){
    color: #cb1616;
}
.list_item img{
    width: 0.135rem;
    object-fit: contain;
    display: block;
    margin-right: 0.1rem;
}
</style>